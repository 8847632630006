<template>
  <v-app>
    <div
      class="bg-body_ d-flex flex-column justify-center align-center"
    >
    <v-container class="mt-6">
      <div class="mx-auto mb-5 cursor-pointer topDes custom-container">
        <div >
          <img
            style="max-width: 300px;max-height: 80px"
            class="text-center"
            :src="logo.value"
            @click="index()"
          />
        </div>
        <h3 class="hidden-xs-only col-F63605">{{ $t("你身边的省钱助手") }}</h3>
      </div>
    </v-container>
    <div class="sign-up-box">
      <div class="sign-up-card-left">

      </div>
      <div class="sign-up-card">
        <div class="sign-up-card-container">
          <div class="text-left mb-10 col-F63605">
            <h3 class="mb-3">{{ $t("重置密码") }}</h3>
            <h5 class="text-sm  grey--text">
              {{ $t("请填写所有表格以继续") }}
            </h5>
          </div>
          <p class="text-14 mb-2">{{ $t("手机号码或邮箱") }}</p>
          <v-text-field
            placeholder="example@mail.com"
            :rules="Rules"
            outlined
            dense
            @blur="verifyUserExists"
            class="mb-4"
            v-model="params.account"
          ></v-text-field>
          <div class="">
            <p class="text-14 mb-1">{{ $t("验证码") }}</p>
            <div class="d-flex">
              <v-text-field
                :rules="Rules"
                outlined
                dense
                v-model="params.captcha"
                class="mb-4 mr-2"
              ></v-text-field>
              <v-btn  color="primary" @click="sendcode()" v-if="!captchaOptions.status">{{
                $t("获取验证码")
              }}</v-btn>
              <v-btn v-else>{{ captchaOptions.seconds }}s</v-btn>
            </div>
          </div>
          <p class="text-14 mb-2">{{ $t("密码") }}</p>
          <v-text-field
            type="password"
            placeholder="*******"
            :rules="Rules"
            outlined
            dense
            v-model="params.password"
            class=""
            @keyup.enter="loginfunc"
          ></v-text-field>
          <div class="mb-4">
            <div>
              {{ $t("即表示您已同意") }}

              <v-tooltip bottom>
                <template v-slot:activator="{ on }">
                  <a target="_blank" href="#" @click.stop v-on="on">
                    {{ $t("条款与协议") }}
                  </a>
                </template>
                Opens in new window
              </v-tooltip>
            </div>
          </div>
          
          <v-btn
            color="primary"
            class="text-capitalize font-600 mb-2 py-5"
            block
            @click="loginfunc"
          >
            {{ $t("提交") }}
          </v-btn>
        </div>
        <div class="py-4  lighten-2 d-flex justify-space-around">
          <div class="text-center">
            <span class="grey--text text--darken-1"
              >{{ $t("已有账号") }}？
              <router-link
                to="/sign-in"
                class="ms-2 col-F63605 text--darken-4 font-600"
                >{{ $t("去登录") }}</router-link
              >
            </span>
          </div>
          
        </div>
      </div>
    </div>
    </div>
  </v-app>
</template>

<script>
export default {
  data() {
    return {
      checkbox: false,
      params: {
        account: "",
        password: "",
        captcha:''
      },
      captchaOptions: {
        status: false,
        seconds: 60,
        timer: null,
      },
      logo:{},
      Rules: [(v) => !!v || "必填项"],
      caigoudialog: false, //采购商弹窗
    };
  },
  mounted() {
    this.logo=this.$store.state.config.logo[0]
  },
  methods: {
    // 验证用户是否存在
    verifyUserExists(){
      this.$api.user.verifyUserExists({username:this.params.account}).then((res)=>{
        if(res.status=='success'){

        }else {
          this.$Toast({ content: res.msg, type: "error" });
        }
      })
    },
    sendcode() {
      if (this.params.account) {
        this.captchaOptions.status = true;
        this.codesub();
        this.$api.user
          .getVerifiCode({ username: this.params.account })
          .then((res) => {
            if (res.status=='error') {
              this.$Toast({ content: res.msg, type: "error" });
            }
          });
      }
    },
    codesub() {
      let seconds = 60,
        that = this;
      this.captchaOptions.timer = setInterval(() => {
        seconds--;
        that.captchaOptions.seconds = seconds;
        if (seconds < 1) {
          that.captchaOptions.status = false;
          that.captchaOptions.seconds = 60;
          clearInterval(that.captchaOptions.timer);
        }
      }, 1000);
    },
    loginfunc() {
      let param={
        username:this.params.account,
        password:this.params.password,
        code:this.params.captcha
      }
      this.$api.user.retrievePassword(param).then((res) => {
        if (res.status=='success') {
          this.$Toast({ content: res.msg, type: "success" });
          setTimeout(() => {
            this.$router.push('/sign-in')   
          }, 1000);
        } else {
          this.$Toast({ content: res.msg, type: "error" });
        }
      });
    },
    index(){
      this.$router.push({
        path: "/",
      });
    }
  },
  destroyed() {
    clearInterval(this.captchaOptions.timer);
  },
};
</script>

<style lang="scss" scoped>
.col-F63605{
  color: #F63605 !important;
}
.bg-body_{
  width: 100%;
  background:url("../assets/images/login/loginbasebg.png");
  height:100%;			//大小设置为100%
  position:fixed;
  background-size:100% 100%;
}

.sign-up-box{
  width: 1100px;
  height: 650px;
  overflow: hidden;
  // margin: 2rem auto;
  background-color: #fff;
  background-size:100% 100%;
  display: flex;
  align-items: center;
  justify-content: space-around;
  background:url("../assets/images/login/loginbg.png");
  @media (max-width: 500px) {
    width: 100%;
    background-image:none
  }
}
.sign-up-card-left{
  width: 526px;
  height: 486px;
  background-size:100% 100%;
  background: url('../assets/images/login/login-left.svg');
  @media (max-width: 500px) {
    width: 0;
  }
}
.sign-up-card {
  width: 400px;
  background-color: #fff;
  // border-radius: 8px;
  // box-shadow: rgb(3 0 71 / 9%) 0px 8px 45px;
  @media (max-width: 500px) {
    width: 100%;
  }
  .sign-up-card-container {
    padding: 3rem 3.75rem 0px;
    @media (max-width: 500px) {
      padding: 3rem 1rem 0px;
    }
  }
}
</style>